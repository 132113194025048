<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>Dados do usuario</h2>
        <hr />
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                v-model="username"
                label="Nome de usuário"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                v-model="email"
                label="Email"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                v-model="setor"
                label="Setor"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <v-text-field
                disabled
                v-model="tipo"
                label="Tipo de usuário"
                required
              ></v-text-field>
            </div>
          </div>

          <router-link to="/painelContasUsuarios" v-slot="{ href, navigate }">
            <a :href="href" class="menu-link" @click="navigate">
              <v-btn class=" textStyle" tile color="#1dd1a1">
                <span style="color: white">Voltar</span>
              </v-btn>
            </a>
          </router-link>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
export default {
  name: "visualizarUsuario",
  async created() {
    try {
      const response = await ambulatorio.indexUsuario(this.$route.params.id);

      if (response.data.usuario.username == "admin") {
        this.setor = "Não definido";
      }

      this.email = response.data.usuario.email;
      this.username = response.data.usuario.username;
      this.tipo = response.data.usuario.usuario_de_type;
      this.setor = response.data.usuario.setor.descricao;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      tipo: "",
      email: "",
      setor: "",
      usernmae: ""
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Visualizar dados do usuário" }
    ]);
  }
};
</script>
